<template>
    <div class="callout">
        <div style="display: flex; gap: 25px; flex-wrap: wrap;">
            <Dropdown optionValue="id" scrollHeight="400px" showClear v-model="tiposeleccionado" :options="tipos" filter
                optionLabel="nombre" style="width:250px" placeholder="Tipo">
            </Dropdown>
            <input style="height: 42px;width:250px" type="text" class="form-control" placeholder="Expediente"
                v-model="filtroExpediente">
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="companiaseleccionada"
                :options="companias" filter optionLabel="nombre" style="width:250px" placeholder="Compañías">
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div v-if="slotProps.option.nombre.length<=50">{{ slotProps.option.nombre }}<span v-if="slotProps.option.prefijo">&nbsp;({{ slotProps.option.prefijo }})</span></div>
                        <div v-else>{{ slotProps.option.nombre.slice(0, 47) }}...<span v-if="slotProps.option.prefijo">&nbsp;({{ slotProps.option.prefijo }})</span></div>

                    </div>
                </template>
            </MultiSelect>
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="operarioseleccionado"
                :options="operarios" filter optionLabel="nombre" style="width:250px" placeholder="Operarios">
            </MultiSelect>
            <MultiSelect scrollHeight="600px" v-model="estadoseleccionado" optionLabel="nombre" :options="estados" optionGroupChildren="items" 
                    optionGroupLabel="label" optionValue="id_estado" style="width:250px" placeholder="Pendiente de">
            </MultiSelect>
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="especialidadseleccionada"
                :options="especialidades" filter optionLabel="nombre" style="width:250px" placeholder="Especialidades">
            </MultiSelect>
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="usuarioseleccionado"
                :options="usuarios" filter optionLabel="nombre" style="width:250px" placeholder="Usuarios">
            </MultiSelect>
            <button class="btn btn-primary" @click="obtenerExpedientes(mievento)">Buscar</button>
        </div>
    </div>
    <div :style="difuminado">
        <tabla-datos :trabajos="true" :botones="true" :columnas="columnas" :datos="expedientes" :filtros="false"
            @marcar="marcar" @revisarSeleccionados="revisarSeleccionados" @desmarcar="desmarcar" :total="total"
            @obtenerExp="obtenerExpedientes($event)" @marcarTodos="marcarTodos" @desmarcarTodos="desmarcarTodos"
            @quieroDatos="obtenerExpedientes($event); mievento=$event" @page="onPage($event)" :page="pageselec"
            :num="numdatos" :servicios="true" v-if="mostrar">
        </tabla-datos>
    </div>
    <Dialog modal dismissableMask="true" :header="cabecera" :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" v-model:visible="visible">
        <p v-html="decode_utf8(mensaje)"></p>
    </Dialog>
</template>
<script>
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
import Dialog from 'primevue/dialog'; export default {
    components:{     
        MultiSelect,
        "tabla-datos": TablaDatos,
        Dialog,
    },
    data() {
        return {
            tiposeleccionado:'',
            tipos:[],
            marcados:[],
            difuminado:'',
            especialidades:[],
            especialidadseleccionada:'',
            mievento:[],
            mostrar:false,
            usuarioseleccionado:'',
            companiaseleccionada:'',
            filtroExpediente: '',
            estados:[],
            estadoseleccionado:[],
                pageselec:1,
                numDatos:15,
                companias:[],
            usuarios:[],
            visible:false,
            operarios:[],
            operarioseleccionado:'',
               total: '',
               expedientes: [],
               parametros: '',
               cabecera:'',
               mensaje:'',
               columnas: [
                   {
                       header: 'Tipo',
                       field: 'class',
                   },
                
                   {
                       header: 'Expediente',
                       field: 'codigo_servicio',
                       sortable: false,
                       acciones: [
                           {
                               link: true,
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               nombreIddos: 'codigo_servicio',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo) => {
                                   console.log('Editar expediente ' + id);
                                   localStorage.setItem('nombreservicio' + id, codigo);
                                   localStorage.setItem('pestanyactivaservicio' + id, 'notas');

                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id: id,
                                           nombreservicio: codigo,
                                       }
                                   });
                               }
                           },
                       ]
                   },
                   {
                       header: 'Compañia',
                       field: 'nombre_compania',
                   },
                   {
                       header: 'Fecha/Hora',
                       field: 'fecha_hora',
                       sortable: true,
                   },
                   {
                       header: 'Operario',
                       field: 'nombre_operario',
                   },
                   {
                       header: 'Estado Pendiente',
                       field: 'nombre_especialidad_pendiente',
                   },
                   {
                       header: 'Especialidad',
                       field: 'nombre_especialidad',
                   },

                   {
                       header: 'Tramitador',
                       field: 'nombre_tramitador',
                       sortable: false,
                   },
                    {
                        header: '',
                        checkbox: 'checkbox',
                    },
                   {
                       data: null,
                       header: "Notas",
                       acciones: [
                           {
                               nombre: 'Nota',
                               class: 'nota',
                               tipo: 'default',
                               nombreId: 'texto_nota',
                               nombreIddos: 'codigo_servicio',

                               icono: 'far fa-clipboard',
                               // Debe ser arrow function para que this sea este componente
                               accion: (texto_nota,codigo_servicio) => {
                                   this.mensaje = texto_nota;
                                   this.cabecera = codigo_servicio;
                                   this.visible = true;
                                                                  }
                           },
                        
                       ]
                   },
                   {
                       data: null,
                       acciones: [
                           
                           {
                               nombre: 'Eliminar',
                               class: 'eliminar',
                               tipo: 'danger',
                               nombreId: 'id_aviso',
                               nombreIddos: 'codigo_servicio',

                               icono: 'far fa-trash-alt',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo_servicio) => {
                                   if (confirm('Desea revisar el trabajo ' + codigo_servicio)) {

                                       this.revisarTrabajo(id);
                                   }
                               }
                           },
                       ]
                   }
               ]
           }
       },
    methods: {
        decode_utf8(s) {
            try {
                return decodeURIComponent(escape(s));
            }
            catch (error) {
                console.log(error);
                return s;
            }
        },
        async revisarSeleccionados(){
            try{
                const api = new PwgsApi();
                await api.put('avisos/revisar-avisos', {tipo:'trabajos_no_revisados', ids_avisos:this.marcados, estado:'1'});
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos revisados correctamente', life: 2000 });
                this.obtenerExpedientes(this.mievento);
            }catch (e) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        marcar(datos){
            console.log('datos', datos);
            this.marcados.push(datos.id_aviso);
            console.log('marcados', this.marcados);
        },
        desmarcar(datos){
            const index = this.marcados.indexOf(datos.id_aviso);
            if(index > -1){
                this.marcados.splice(index, 1);
            }
            console.log('marcados', this.marcados);
        },
        async revisarTrabajo(id){
            try{
                const api = new PwgsApi();
                await api.put('avisos/revisar-avisos', {tipo:'trabajos_no_revisados', ids_avisos:[id], estado:'1'});
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajo revisado correctamente', life: 2000 });
                this.obtenerExpedientes(this.mievento);
            }catch (e) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        async cargarEspecialidades() {
            const api = new PwgsApi();
            const datos = await api.get('especialidades');
            this.especialidades = datos.datos;
        },
        async cargarEstados() {
            const api = new PwgsApi();
            const resp2 = await api.get('tipos-estado-pendiente');
            this.estados = [
            {
                label:'Estados pendientes',
                items:resp2.datos.estados_pendientes
            },
            {
                label:'Especialidades',
                items:resp2.datos.especialidades
            }]
           }, 
        async eliminartrabajo(id_aviso) {
            //put pwgsapi/index.php/avisos/revisar-avisos
           const api = new PwgsApi();
           let ids = [];
           ids.push(id_aviso);
            let subidadatos = {tipo:'trabajos_no_revisados',ids_avisos:ids,estado:1,}
           await api.put('avisos/revisar-avisos', subidadatos);
        },
        async cargarCompanias() {
            const api = new PwgsApi();
            const datos = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = datos.datos;
        },
        async cargarOperarios() {
            const api = new PwgsApi();
            const datos = await api.get('operarios/simple');
            this.operarios = datos.datos;
        },
        async cargarTipos() {
            const api = new PwgsApi();
            const datos = await api.get('tipos-notas');
            for(let element of  Object.entries(datos)){
                var tipo = {
                    id: element[0],
                    nombre: element[1]
                }
                this.tipos.push(tipo);
            }
        },
        async cargarUsuarios() {
            const api = new PwgsApi();
            const datos = await api.get('usuarios/gestion,simple');
            this.usuarios = datos.datos;
        },
            onPage(evnt){
                localStorage.setItem("pageselectnr", evnt.page);
                localStorage.setItem("numdatstnr", evnt.rows);
                console.log("evvvv", evnt);
            },
            marcarTodos(){
                console.log('marcatodos');
                this.marcados = [];
                this.expedientes.forEach(item => {
                    item.marcado = true;
                    this.marcados.push(item.id_aviso);
                });
                console.log('marcados', this.marcados);
            },
            desmarcarTodos(){
                this.marcados = [];
                console.log('desmarcatodos');
                this.expedientes.forEach(item => {
                    item.marcado = false;
                });
            },
           async obtenerExpedientes(event) {
            this.marcados = [];
                this.difuminado = "filter:blur(4px)";
                console.log('estadosele', this.estadoseleccionado);
                if(this.especialidadseleccionada){
                    event.ids_especialidades = this.especialidadseleccionada;
                }
                if(this.estadoseleccionado){
                    event.ids_esp_pend = this.estadoseleccionado;
                }
                if(this.operarioseleccionado){
                    event.ids_operarios = this.operarioseleccionado;
                }
                if(this.tiposeleccionado){
                    event.tipo = this.tiposeleccionado;
                }
                if (this.companiaseleccionada) {
                event.ids_companias = this.companiaseleccionada;
                }
                if (this.usuarioseleccionado) {
                event.ids_usuarios = this.usuarioseleccionado;
                }
                if(this.filtroExpediente !=''){
                    event.buscador = this.filtroExpediente;
                }else{
                    delete event.buscador;
                }
               const api = new PwgsApi;
               try{
                   const aux = await api.post('avisos/trabajos-no-revisados',event);
                   console.log('aux',aux);
                   this.expedientes = aux.datos.map(function (x) {
                    x.marcado = false;
                    return x;
                   });
                   console.log('expedientess', this.expedientes);
                   this.total = aux.n_total_registros;
                   this.$emit('evento_total_trabajos_no_revisados', this.total); //Es llamado por ./Escritorio.vue
                   this.difuminado = '';
               }catch(e) {
                    this.difuminado = '';
                    console.error(e);
                    this.expedientes = [];
                    this.total = 0;
                }
           },
           toggle(event) {
               this.$refs.op.toggle(event);
           }
       },
       activated() {
           //this.obtenerExpedientes();
       },
           mounted() {
            this.cargarCompanias();
            this.cargarUsuarios();
            this.cargarOperarios();
            this.cargarEspecialidades();
            this.cargarEstados();
            this.cargarTipos();
            this.pageselec = parseInt(localStorage.getItem('pageselectnr'));
            this.numdatos = parseInt(localStorage.getItem('numdatstnr'));
            console.log('pageselec',this.pageselec);
            this.mostrar = true;
           //this.obtenerExpedientes();
       },
        watch:{
            estadoseleccionado(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.estadoseleccionado) {
                    delete this.mievento["ids_esp_pend"];
                }
            },
            especialidadseleccionada(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.especialidadseleccionada) {
                    delete this.mievento["ids_especialidades"];
                }
            },
            operarioseleccionado(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.operarioseleccionado) {
                    delete this.mievento["ids_operarios"];
                }
            },
            usuarioseleccionado(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.usuarioseleccionado) {
                    delete this.mievento["ids_usuarios"];
                }
            },
            companiaseleccionada(){
                this.pageselec = 1;
                this.mievento["first"]=0;
                if (!this.companiaseleccionada) {
                    delete this.mievento["ids_companias"];
                }
            },
            filtroExpediente(){
                this.pageselec = 1;
                this.mievento["first"]=0;
            },
            tiposeleccionado(){
            this.pageselec = 1;
            this.mievento["first"]=0;
            if (!this.tiposeleccionado) {
                delete this.mievento["tipo"];
            }
        }
        }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   </style>