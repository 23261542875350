<template>
    <div class="callout" style="margin: 0;">
        <Button icon="fas fa-file-excel" class="btn btn-secondary" label="Exportar" style="height: 42px;" @click="exportarcsv()" />
    </div>
    <div style="margin-top: 10px;">
        <tabla-datos 
            stripedRows
            :value="expedientesFiltrados" 
            :loading="loading"
            :total="total"
            paginator
            :rows="15"
            :rowsPerPageOptions="[15, 25, 50]"
            tableStyle="min-width: 50rem">
            <template #empty> No hay datos. </template>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">                    
                       <input class="form-control" type="checkbox" v-model="checkGlobal">
                    </div>
                </template>
                <template #body="{ data }">
                    <input type="checkbox" v-model="data.seleccionado">
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">  
                        <span>Nº Expediente</span>
                        <button @click="toggleOrden('codigo')" :aria-sort="codigoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                            <i :class="codigoIcon"></i>
                        </button>
                    </div>
                </template>
                <template #body="{ data }">
                    <span style="cursor: pointer;" @click="abrirServicio(data)">{{ data.codigo }}</span>
                </template>
            </Column>
            <Column v-if="tipo_servicio == 'sin_confirmar'">
                <template #header>
                    <div class="d-flex justify-content-between">
                        <span>Fecha Cita y hora</span>
                        <button @click="toggleOrden('fecha_inicio')" :aria-sort="fechaInicioOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                            <i :class="fechaInicioIcon"></i>
                        </button>
                    </div>
                </template>
                <template #body="{ data }">
                    {{ data.fecha_inicio }}
                </template>
            </Column>
            <Column  v-else>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <span>Fecha Cita y hora</span>
                    </div>
                </template>
                <template #body="{ data }">
                    {{ data.fecha_inicio }}
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">                    
                       Datos del asegurado
                    </div>
                </template>
                <template #body="{ data }">
                    <p v-html="data.cliente"></p>
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <MultiSelect style="width: 200px;" scrollHeight="400px" showClear v-model="OperarioSeleccionado" :options="operarios" optionValue="id" 
                        filter optionLabel="nombre" placeholder="Operario">
                        </MultiSelect>
                        <button @click="toggleOrden('operario')" :aria-sort="operarioOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                            <i :class="operarioIcon"></i>
                        </button>
                    </div>
                </template>
                <template #body="{ data }">
                    <p v-html="data.operario"></p>                
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <select style="width: 200px;" class="form-control" v-model="tramitadorSeleccionado">
                            <option value="">Tramitador</option>
                            <option v-for="tram in tramitadores" :key="tram.id" :value="tram.id">{{ tram.nombre }}</option>
                        </select>
                    </div>
                </template>
                <template #body="{ data }">
                    <p v-html="data.tramitador"></p>                
                </template>
            </Column>
            <Column>
                <template #header>
                    <MultiSelect style="width: 200px;" scrollHeight="400px" showClear v-model="especialidadSeleccionada" :options="especialidades" optionValue="id" 
                        filter optionLabel="nombre" placeholder="Especialidad">
                    </MultiSelect>
                </template>
                <template #body="{ data }">
                    <p v-html="data.especialidad"></p>                
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">                    
                       Resumen de parte de trabajo
                    </div>
                </template>
                <template #body="{ data }">
                    {{ data.descripcion_visitadores }}
                </template>
            </Column>
        </tabla-datos>
    </div>
    <div class="row" style="margin: 10px 0px; gap: 10px; align-items: center;">
        <p style="margin: 0;">Desactivar seleccionadas</p>
        <div>
            <button @click="desactivar" class="btn btn-sm btn-light" style="border: 1px solid grey;">Desactivar</button>
        </div>
    </div>
   
</template>
   
   <script>
   import jsonToCsvExport from "json-to-csv-export";
   import { PwgsApi } from '../../../services/PwgsApi';
   import DataTable from 'primevue/datatable';
   import MultiSelect from 'primevue/multiselect';
   export default {
       components: {         
           "tabla-datos": DataTable,
           MultiSelect,
       },
       props:{
         tipo_servicio: String
       },
       data() {
           return {
            tramitadores:[],
            tramitadorSeleccionado:'',
            ordenarPor:'codigo',
            especialidades: [],
            especialidadSeleccionada:[],
            operarios:[],
            OperarioSeleccionado:[],
            checkGlobal: false,
            total: '',
            expedientes: [], 
            loading:false,
            codigoOrdenAscendente: true,
            operarioOrdenAscendente: false,
            fechaInicioOrdenAscendente: false,
            codigoIcon: 'fas fa-sort-down',
            operarioIcon: 'fas fa-sort-up',
            fechaInicioIcon: 'fas fa-sort-up',                   
           }
       },
       computed:{
        expedientesFiltrados(){
            let filtrados = this.expedientes;
            if(this.OperarioSeleccionado.length>0){
                filtrados = filtrados.filter(exp => this.OperarioSeleccionado.includes(exp.id_operario));
            }
            if(this.tramitadorSeleccionado){
                filtrados = filtrados.filter(exp => exp.id_tramitador == this.tramitadorSeleccionado);
            }
            if(this.especialidadSeleccionada.length>0){
                filtrados = filtrados.filter(exp => this.especialidadSeleccionada.includes(exp.id_especialidad));
            }
            filtrados.sort((a, b) => {
                if(this.ordenarPor == 'codigo') {                        
                    const valorA = a.codigo.toLowerCase();
                    const valorB = b.codigo.toLowerCase();
                    return this.codigoOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                }else if(this.ordenarPor == 'fecha_inicio') {                        
                    const fechaA = this.parseDate(a.fecha_inicio);
                    const fechaB = this.parseDate(b.fecha_inicio);
                    return this.fechaInicioOrdenAscendente ? fechaB - fechaA : fechaA - fechaB;
                }else{                        
                    const valorA = a.operario.toLowerCase();
                    const valorB = b.operario.toLowerCase();
                    return this.operarioOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                }
            });
            return filtrados;
        }
       },      
       methods: {
        async exportarcsv() {
           const api = new PwgsApi();
           try{
               this.$toast.add({ severity: 'success', summary: 'Descarga iniciada', detail: 'Descargando...', life: 5000 });               
               var aux = await api.post('avisos/visitas/'+this.tipo_servicio);
               var expedientes = [];
                expedientes = aux.datos.map(expediente => {
                    return {
                        ...expediente,
                        fecha_inicio: expediente.fecha_inicio
                    };
                });
                expedientes = expedientes.map(expediente => {
                    return {
                        ...expediente,
                        fecha_fin: expediente.fecha_fin
                    };
                });
                expedientes = expedientes.map(expediente => {
                    var tramitadorEncontrado = this.tramitadores.filter(tramitador => tramitador.id == expediente.id_tramitador); 
                    console.log('tramitadores', this.tramitadores, expediente, tramitadorEncontrado);    
                    if(tramitadorEncontrado.length > 0){
                        return {
                            ...expediente,
                            tramitador: tramitadorEncontrado[0].nombre
                        };
                    }else{
                        return{
                            ...expediente,
                            tramitador: ''
                        }
                    }
                });
                let filtrados = expedientes;
                if(this.OperarioSeleccionado.length > 0){
                    filtrados = filtrados.filter(exp => this.OperarioSeleccionado.includes(exp.id_operario));
                }
                if(this.tramitadorSeleccionado){
                    filtrados = filtrados.filter(exp => exp.id_tramitador == this.tramitadorSeleccionado);
                }
                if(this.especialidadSeleccionada.length>0){
                    filtrados = filtrados.filter(exp => this.especialidadSeleccionada.includes(exp.id_especialidad));
                }
                var listado = [];
                filtrados.forEach(filtrado => {
                    let csv = {};
                    csv['Nº Expediente'] = filtrado.codigo;
                    csv['Fecha creación visita'] = filtrado.fecha_crear_visita;
                    csv['Datos del asegurado'] = filtrado.cliente.replaceAll('<br>', ' ');
                    csv['Operario'] = filtrado.operario.replaceAll('<br>', ' ');
                    csv['Tramitador'] = filtrado.tramitador;
                    csv['Especialidad'] = filtrado.especialidad;
                    csv['Resumen de parte de trabajo'] = filtrado.descripcion_visitadores;
                    listado.push(csv);
                });
                
                let fechahoy = '_F' + new Date().toLocaleDateString().replaceAll('/', '-') + '_T' + new Date().toLocaleTimeString().replaceAll(':', '-');
               jsonToCsvExport({ data: listado, filename: 'Descarga_avisos_' + fechahoy, delimiter: ";" });      
           }catch (e) {                
               this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
           }
            //let text = "hola mondo";
           
        },
            abrirServicio(data){
                console.log("abrirServicio", data);
                localStorage.setItem('nombreservicio'+data.id_servicio,data.codigo);
                this.$router.push({
                    name: 'Servicio',
                    params: {
                        id: data.id_servicio,
                        nombreservicio:data.codigo,
                    }
                });
            },
            toggleOrden(campo) {
                this.ordenarPor = campo;
                if(campo == 'fecha_inicio'){
                    this.fechaInicioOrdenAscendente = !this.fechaInicioOrdenAscendente;
                    this.fechaInicioIcon = this.fechaInicioOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                    this.codigoOrdenAscendente = false;
                    this.codigoIcon = 'fas fa-sort-up';
                    this.operarioAscendente = false;
                    this.operarioIcon = 'fas fa-sort-up';
                }else if(campo == 'codigo'){
                    this.codigoOrdenAscendente = !this.codigoOrdenAscendente;
                    this.codigoIcon = this.codigoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                    this.fechaInicioOrdenAscendente = false;
                    this.fechaInicioIcon = 'fas fa-sort-up';
                    this.operarioOrdenAscendente = false;
                    this.operarioIcon = 'fas fa-sort-up';
                }else{
                    this.operarioOrdenAscendente = !this.operarioOrdenAscendente;
                    this.operarioIcon = this.operarioOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                    this.codigoOrdenAscendente = false;
                    this.codigoIcon = 'fas fa-sort-up';
                    this.fechaInicioOrdenAscendente = false;
                    this.fechaInicioIcon = 'fas fa-sort-up';
                }
            },
            parseDate(dateString) {
                const [day, month, year] = dateString.split('/');
                return new Date(`${year}-${month}-${day}`);
            },
            async desactivar(){
                var exps = this.expedientes.filter(expediente => expediente.seleccionado == true);
                var idexps = [];
                try{
                    if(exps.length > 0){
                        const api = new PwgsApi();
                        for(let exp of exps){
                            idexps.push(exp.id_aviso);
                        }
                        await api.put('avisos/revisar-avisos', {tipo:'visitas', modo: this.tipo_servicio, ids_avisos:idexps});
                        this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Alertas desactivadas correctamente', life: 2000 });
                        this.OperarioSeleccionado=[];
                        this.especialidadSeleccionada=[];
                        this.obtenerExpedientes();
                        this.checkGlobal = false;        
                    }
                }catch(e){
                    console.log(e);
                }
            }, 
           async obtenerExpedientes() {
                console.log('obtiene1');
                this.loading = true;
               const api = new PwgsApi;       
               var aux = await api.post('avisos/visitas/'+this.tipo_servicio);
               this.expedientes = aux.datos;
               for(let expediente of this.expedientes){
                expediente.seleccionado = false;
                var tramitadorEncontrado = this.tramitadores.filter(tramitador => tramitador.id == expediente.id_tramitador);
                if(tramitadorEncontrado.length > 0) {
                    expediente.tramitador = tramitadorEncontrado[0].nombre;
                }
               }
               this.total = aux.n_total_registros;
               localStorage.setItem("totalavisos", this.total);
               this.loading = false;
           },
           async obtenerOperarios() {
            const api = new PwgsApi();
            var aux = await api.get('operarios/simple');
            this.operarios = aux.datos;
           },
           async obtenerEspecialidades() {
                //GET /pwgsapi/index.php/especialidades
                const api = new PwgsApi();
                let aux = await api.get('especialidades');
                this.especialidades = aux.datos;
            },
           seleccionartodo(){
            for(let expediente of this.expedientes){
                expediente.seleccionado = this.checkGlobal;
            }
           },
           async obtenerTramitadores(){
            const api = new PwgsApi();
            const resp = await api.get('usuarios/Gestión?sortField=nombre&sortOrder=1');
            this.tramitadores = resp.datos;
            this.obtenerExpedientes();
           },
       },
       created() {            
           this.obtenerOperarios();
           this.obtenerTramitadores();
            this.obtenerEspecialidades();
       },        
       watch:{
        checkGlobal(){            
            this.seleccionartodo();            
        }
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>