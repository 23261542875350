<template>
    <div class="dropdown-item" v-for="aviso in avisos" :key="aviso.id_aviso" :style="aviso.style">
        <div data-toggle="tooltip" data-placement="top">
            <i :class="aviso.class" :title="aviso.texto"></i>{{aviso.titulo}}
            <span class="float-right text-muted " v-if="aviso.numero_avisos >0 || aviso.numero_alertas>0">
                <a v-if="aviso.numero_avisos" @click="cambiartotal(aviso.numero_alertas); cargarExpedienteAvisos(aviso.tipo_servicio,aviso.titulo,1)" href="#"><span class="badge bg-warning float-left">{{aviso.numero_avisos}}</span></a>
                <a v-if="aviso.numero_alertas" @click="cambiartotal(aviso.numero_alertas); cargarExpedienteAvisos(aviso.tipo_servicio,aviso.titulo,2)" href="#">&nbsp;<span class="badge bg-danger float-right">{{aviso.numero_alertas}}</span></a>
            </span>
            <span class="float-right text-muted " v-else>{{aviso.texto}}</span>
        </div>
    </div>
</template>
<script>
export default({
    props:{
        avisos: Array,
        
    },
    
    setup() {

        
    },
    methods:{
        cargarExpedienteAvisos: function(tipo,titulo,alerta){
            //alert(tipo);
            //window.location.href = "/escritorio";
            this.$emit('evento_expediente_avisos_tipo',tipo,titulo,alerta); //Es llamado por ./AvisosMenu.vue
        },
        cambiartotal(numero) {
            console.log("parent", this.$parent);
            console.log("parentnumero", numero);
            localStorage.setItem("numeroavisos", numero);
            // this.$parent.cambiartotalpadre(numero);
            setTimeout(() => {
                console.log("hola123", document.getElementById("avescritorio"));
                document.getElementById("avescritorio").className = "nav-link active";

            }, 500);

        },
    },
    mounted(){
    },
    watch:{
        avisos() {
            console.log("vienede2", this.$props.vienede);

            //console.log('avisos', this.avisos)
        }
    }
})
</script>
