<template>
    <div class="callout">
        <div style="display: flex; gap: 25px; flex-wrap: wrap;">
            <Dropdown optionValue="id" scrollHeight="400px" showClear v-model="tiposeleccionado" :options="tipos" filter
                optionLabel="nombre" style="width:250px" placeholder="Tipo">
            </Dropdown>
            <input style="height: 42px;width:250px" type="text" class="form-control" placeholder="Expediente"
                v-model="filtroExpediente">
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="companiaseleccionada"
                :options="companias" filter optionLabel="nombre" style="width:250px" placeholder="Compañías">
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div v-if="slotProps.option.nombre.length <= 50">{{ slotProps.option.nombre }}<span
                                v-if="slotProps.option.prefijo">&nbsp;({{ slotProps.option.prefijo }})</span></div>
                        <div v-else>{{ slotProps.option.nombre.slice(0, 47) }}...<span
                                v-if="slotProps.option.prefijo">&nbsp;({{ slotProps.option.prefijo }})</span></div>

                    </div>
                </template>
            </MultiSelect>
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="usuarioseleccionado"
                :options="usuarios" filter optionLabel="nombre" style="width:250px" placeholder="Usuarios">
            </MultiSelect>
            <button class="btn btn-primary" @click="obtenerExpedientes(mievento)">Buscar</button>
        </div>
    </div>
    <div :style="difuminado">
        <tabla-datos :botones="true" :solicitudes="true" :columnas="columnas" :datos="expedientes" :filtros="false"
            :total="total" @page="onPage($event)" @obtenerExp="obtenerExpedientes($event)"
            @quieroDatos="obtenerExpedientes($event); mievento = $event;" :page="pageselec" :num="numdatos"
            @eliminarSeleccionadas="eliminarSeleccionadas" @marcar="marcar" @desmarcar="desmarcar"
            @marcarTodos="marcarTodas" @desmarcarTodos="desmarcarTodas" :servicios="true" v-if="mostrar">
        </tabla-datos>
    </div>
</template>   
<script>
import MultiSelect from 'primevue/multiselect';
   import { PwgsApi } from '../../../services/PwgsApi';
   import TablaDatos from '../../TablaDatos.vue';
   import Dropdown from 'primevue/dropdown';
   export default {
       components: {
        MultiSelect,
           "tabla-datos": TablaDatos,
           Dropdown,
       },
       data() {
           return {
            tiposeleccionado:'',
            tipos:[],
            marcados:[],
            difuminado:'',
            mievento:[],
                mostrar:false,
                pageselec:1,
                numDatos:15,
                companias:[],
                filtroExpediente: '',
                companiaseleccionada: '',
                usuarioseleccionado: '',               
            usuarios:[],
               total: '',
               expedientes: [],
               parametros: '',
               columnas: [
                   {
                       header: 'Tipo',
                       field: 'class',
                   },
                   { 
                       header: 'Expediente',
                       field: 'codigo_servicio',
                       sortable: false,
                       acciones: [
                           {
                               link: true,
                               tipo: 'default',
                               nombreId: 'id_servicio',
                               nombreIddos: 'codigo_servicio',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo) => {
                                   console.log('Editar expediente ' + id);
                                   localStorage.setItem('nombreservicio' + id, codigo);
                                   localStorage.setItem('pestanyactivaservicio' + id, 'comunicaciones');

                                   this.$router.push({
                                       name: 'Servicio',
                                       params: {
                                           id: id,
                                           nombreservicio: codigo,
                                       }
                                   });
                               }
                           },
                       ]
                   },
                   {
                       header: 'Compañia',
                       field: 'nombre_compania',
                   },
                   {
                       header: 'Fecha/Hora',
                       field: 'fecha_hora',
                       sortable: true,
                   },
                   {
                       header: 'Mensaje',    
                       field: 'texto',
                   },
                   {
                       header: 'Estado',
                       field: 'estado_servicio',
                   },
                   {
                       header: 'Tramitador',
                       field: 'nombre_tramitador',
                       sortable: false,
                   },
                   {
                        header: '',
                        checkbox: 'checkbox',
                    },
                   {
                       data: null,
                       acciones: [
                           
                           {
                               nombre: 'Eliminar',
                               class: 'eliminar',
                               tipo: 'danger',
                               nombreId: 'id_aviso',
                               nombreIddos: 'codigo_servicio',

                               icono: 'far fa-trash-alt',
                               // Debe ser arrow function para que this sea este componente
                               accion: (id, codigo_servicio) => {
                                   if (confirm('Desea eliminar la solicitud ' + codigo_servicio)) {

                                       this.eliminarsolicitud(id);
                                   }
                               }
                           },
                       ]
                   }
               ]
           }
       },
       methods: {
            async eliminarSeleccionadas(){
                try{
                    const api = new PwgsApi();
                    await api.put('avisos/revisar-avisos', {tipo:'solicita', ids_avisos:this.marcados, estado:'1'});
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos revisados correctamente', life: 2000 });
                    this.obtenerExpedientes(this.mievento);
                }catch (e) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
                }
            },
            marcar(datos){
                console.log('datos', datos);
                this.marcados.push(datos.id_aviso);
                console.log('marcados', this.marcados);
            },
            desmarcar(datos){
                const index = this.marcados.indexOf(datos.id_aviso);
                if(index > -1){
                    this.marcados.splice(index, 1);
                }
                console.log('marcados', this.marcados);
            },
            async cargarCompanias() {
                const api = new PwgsApi();
                const datos = await api.get('companias/simple?sortField=nombre&sortOrder=1');
                this.companias = datos.datos;
            },
            async cargarUsuarios() {
                const api = new PwgsApi();
                const datos = await api.get('usuarios/gestion,simple');
                this.usuarios = datos.datos;
            },
            async cargarTipos() {
                const api = new PwgsApi();
                const datos = await api.get('tipos-solicitudes');
                for(let element of  Object.entries(datos)){
                    var tipo = {
                        id: element[0],
                        nombre: element[1]
                    }
                    this.tipos.push(tipo);
                }
            },
            marcarTodas(){
                console.log('marcatodos');
                this.marcados = [];
                this.expedientes.forEach(item => {
                    item.marcado = true;
                    this.marcados.push(item.id_aviso);
                });
                console.log('marcados', this.marcados);
            },
            desmarcarTodas(){
                this.marcados = [];
                console.log('desmarcatodos');
                this.expedientes.forEach(item => {
                    item.marcado = false;
                });
            },
            onPage(evnt){
                localStorage.setItem("pageselecsol", evnt.page);
                localStorage.setItem("numdatssol", evnt.rows);
                console.log("evvvv", evnt);
            },
            async obtenerExpedientes(event) {
                this.marcados = [];
                this.difuminado = "filter:blur(4px)";
                if (this.companiaseleccionada) {
                event.ids_companias = this.companiaseleccionada;
                }
                if(this.tiposeleccionado){
                    event.tipo = this.tiposeleccionado;
                }
                if (this.usuarioseleccionado) {
                event.ids_usuarios = this.usuarioseleccionado;
                }
                if(this.filtroExpediente !=''){
                    event.buscador = this.filtroExpediente;
                }else{
                    delete event.buscador;
                }
                try{
                    const api = new PwgsApi;
                    const aux = await api.post('avisos/solicitudes', event);
                    console.log('aux',aux);
                    this.expedientes = aux.datos.map(function (x) {
                        x.marcado = false;
                        return x;
                    });
                    this.total = aux.n_total_registros;
                    this.$emit('evento_total_solicitudes', this.total); //Es llamado por ./Escritorio.vue
                    this.difuminado = '';
                }catch(e) {
                    console.error('Error al obtener los expedientes', e);
                    this.difuminado = '';
                    this.expedientes = [];
                    this.total = 0;
                }
            },
            async eliminarsolicitud(id_aviso) {
                //put pwgsapi/index.php/avisos/revisar-avisos
                const api = new PwgsApi();
                let ids = [];
                ids.push(id_aviso);
                let subidadatos = { tipo: 'solicita', ids_avisos: ids, estado: 1, }
                try{
                    await api.put('avisos/revisar-avisos', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Solicitud eliminada correctamente', life: 2000 });
                    this.obtenerExpedientes(this.mievento);
                }catch(e){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
                }
            }
       },
       watch: {
        usuarioseleccionado(){
            this.pageselec = 1;
            this.mievento["first"]=0;
            if (!this.usuarioseleccionado) {
                delete this.mievento["ids_usuarios"];
            }
        },
        companiaseleccionada(){
            this.pageselec = 1;
            this.mievento["first"]=0;
            if (!this.companiaseleccionada) {
                delete this.mievento["ids_companias"];
            }
        },
        tiposeleccionado(){
            this.pageselec = 1;
            this.mievento["first"]=0;
            if (!this.tiposeleccionado) {
                delete this.mievento["tipo"];
            }
        }
    },
       activated() {
          // this.obtenerExpedientes();
       },
        mounted() {
            this.cargarCompanias();
            this.cargarUsuarios();
            this.cargarTipos();
            this.pageselec = parseInt(localStorage.getItem('pageselecsol'));
            this.numdatos = parseInt(localStorage.getItem('numdatssol'));
            console.log('pageselec',this.pageselec);
            this.mostrar = true;
            //this.obtenerExpedientes();
       }
   }
   </script>
<style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>