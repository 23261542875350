<template>
    <div class="callout" style="margin: 0;">
        <Button icon="fas fa-file-excel" class="btn btn-secondary" label="Exportar" style="height: 42px;"
            @click="exportarcsv()" />
    </div>
    <div style="margin-top: 10px;">
        <tabla-datos stripedRows :value="expedientesFiltrados" :loading="loading" :total="total" paginator :rows="15"
            :rowsPerPageOptions="[15, 25, 50]" tableStyle="min-width: 50rem">
            <template #empty> No hay datos. </template>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <span>Nº Expediente</span>
                        <button @click="toggleOrden('codigo')"
                            :aria-sort="codigoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                            <i :class="codigoIcon"></i>
                        </button>
                    </div>
                </template>
                <template #body="{ data }">
                    <span style="cursor:pointer" @click="iraServicio(data, data.id_servicio,data.codigo)"> {{ data.codigo }}</span>
                </template>
            </Column>
            <Column>
                <template #body="{ data }">
                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;"
                        @click="confirmar(data)">Confirmar</button>
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <span>Fecha creación de visita</span>
                        <button @click="toggleOrden('fecha_crear_visita')"
                            :aria-sort="fechaInicioOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                            <i :class="fechaInicioIcon"></i>
                        </button>
                    </div>
                </template>
                <template #body="{ data }">
                    {{ data.fecha_crear_visita }}
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-center">
                        Fecha Cita y hora
                    </div>
                </template>
                <template #body="{ data }">
                    <div style="align-items: center; display: flex; flex-flow: row wrap; gap: 15px;">
                        <div>
                            <Calendar dateFormat="dd/mm/yy" v-model="data.fecha_inicio" id="calendar-24h" showTime
                                hourFormat="24" />
                        </div>
                        <div>
                            <Calendar dateFormat="dd/mm/yy" v-model="data.fecha_fin" id="calendar-24h" showTime
                                hourFormat="24" />
                        </div>
                    </div>
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        Datos del asegurado
                    </div>
                </template>
                <template #body="{ data }">
                    <p v-html="data.cliente"></p>
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <MultiSelect style="width: 100px;" scrollHeight="400px" showClear v-model="OperarioSeleccionado"
                            :options="operarios" optionValue="id" filter optionLabel="nombre" placeholder="Operario">
                        </MultiSelect>
                        <button @click="toggleOrden('operario')"
                            :aria-sort="operarioOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                            <i :class="operarioIcon"></i>
                        </button>
                    </div>
                </template>
                <template #body="{ data }">
                    <p v-html="data.operario"></p>
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <select style="width: 100px;" class="form-control" v-model="tramitadorSeleccionado">
                            <option value="">Tramitador</option>
                            <option v-for="tram in tramitadores" :key="tram.id" :value="tram.id">{{ tram.nombre }}
                            </option>
                        </select>
                    </div>
                </template>
                <template #body="{ data }">
                    <p v-html="data.tramitador"></p>
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        <MultiSelect style="width: 100px;" scrollHeight="400px" showClear
                            v-model="especialidadSeleccionada" :options="especialidades" optionValue="id" filter
                            optionLabel="nombre" placeholder="Especialidad">
                        </MultiSelect>
                    </div>
                </template>
                <template #body="{ data }">
                    <p v-html="data.especialidad"></p>
                </template>
            </Column>
            <Column>
                <template #header>
                    <div class="d-flex justify-content-between">
                        Resumen de parte de trabajo
                    </div>
                </template>
                <template #body="{ data }">
                    {{ data.descripcion_visitadores }}
                </template>
            </Column>
        </tabla-datos>
    </div>
</template>
   
   <script>
   import jsonToCsvExport from "json-to-csv-export";
   import Calendar from 'primevue/calendar';
import { PwgsApi } from '../../../services/PwgsApi';
   import DataTable from 'primevue/datatable';
   import MultiSelect from 'primevue/multiselect';
   
   export default {
       components: {         
           "tabla-datos": DataTable,
           Calendar,
           MultiSelect,
           
       },
       props:{
         tipo_servicio: String
       }
       ,
       computed:{
            expedientesFiltrados(){
                let filtrados = this.expedientes;
                if(this.OperarioSeleccionado.length > 0){
                    filtrados = filtrados.filter(exp => this.OperarioSeleccionado.includes(exp.id_operario));
                }
                if(this.tramitadorSeleccionado){
                    filtrados = filtrados.filter(exp => exp.id_tramitador == this.tramitadorSeleccionado);
                }
                if(this.especialidadSeleccionada.length>0){
                    filtrados = filtrados.filter(exp => this.especialidadSeleccionada.includes(exp.id_especialidad));
                }
                filtrados.sort((a, b) => {
                    if(this.ordenarPor == 'codigo') {                     
                        const valorA = a.codigo.toLowerCase();
                        const valorB = b.codigo.toLowerCase();
                        return this.codigoOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                    }else if(this.ordenarPor == 'fecha_crear_visita') {                        
                        const fechaA = this.parseDate(a.fecha_crear_visita);
                        const fechaB = this.parseDate(b.fecha_crear_visita);
                        return this.fechaInicioOrdenAscendente ? fechaB - fechaA : fechaA - fechaB;
                    }else{                        
                        const valorA = a.operario.toLowerCase();
                        const valorB = b.operario.toLowerCase();
                        return this.operarioOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                    }
                });
                return filtrados;
            }           
       },
       data() {
           return {
            especialidades: [],
            tramitadores:[],
            tramitadorSeleccionado:'',
            especialidadSeleccionada:[],
            operarios:[],
            OperarioSeleccionado:[],
            checkGlobal: false,
            total: '',
            expedientes: [], 
            loading:false,
            codigoOrdenAscendente: true,
            operarioOrdenAscendente: false,
            fechaInicioOrdenAscendente: false,
            codigoIcon: 'fas fa-sort-down',
            operarioIcon: 'fas fa-sort-up',
            fechaInicioIcon: 'fas fa-sort-up',
            ordenarPor:'codigo',                      
           }
       },
    methods: {
        iraServicio(datos, id, codigo) {
            console.log("datos", datos);
            localStorage.setItem('nombreservicio' + id, codigo);
            this.$router.push({
                name: 'Servicio',
                params: {
                    id: id,
                    nombreservicio: codigo,
                }
            });
        },
        async exportarcsv() {
           const api = new PwgsApi();
           try{
               this.$toast.add({ severity: 'success', summary: 'Descarga iniciada', detail: 'Descargando...', life: 5000 });               
               var aux = await api.post('avisos/visitas/'+this.tipo_servicio);
               var expedientes = [];
                expedientes = aux.datos.map(expediente => {
                    return {
                        ...expediente,
                        fecha_inicio: expediente.fecha_inicio
                    };
                });
                expedientes = expedientes.map(expediente => {
                    return {
                        ...expediente,
                        fecha_fin: expediente.fecha_fin
                    };
                });
                expedientes = expedientes.map(expediente => {
                    var tramitadorEncontrado = this.tramitadores.filter(tramitador => tramitador.id == expediente.id_tramitador); 
                    console.log('tramitadores', this.tramitadores, expediente, tramitadorEncontrado);    
                    if(tramitadorEncontrado.length > 0){
                        return {
                            ...expediente,
                            tramitador: tramitadorEncontrado[0].nombre
                        };
                    }else{
                        return{
                            ...expediente,
                            tramitador: ''
                        }
                    }
                });
                let filtrados = expedientes;
                if(this.OperarioSeleccionado.length > 0){
                    filtrados = filtrados.filter(exp => this.OperarioSeleccionado.includes(exp.id_operario));
                }
                if(this.tramitadorSeleccionado){
                    filtrados = filtrados.filter(exp => exp.id_tramitador == this.tramitadorSeleccionado);
                }
                if(this.especialidadSeleccionada.length>0){
                    filtrados = filtrados.filter(exp => this.especialidadSeleccionada.includes(exp.id_especialidad));
                }
                var listado = [];
                filtrados.forEach(filtrado => {
                    let csv = {};
                    csv['Nº Expediente'] = filtrado.codigo;
                    csv['Fecha creación visita'] = filtrado.fecha_crear_visita;
                    csv['Fecha inicio cita'] = filtrado.fecha_inicio;
                    csv['Fecha fin cita'] = filtrado.fecha_fin;
                    csv['Datos del asegurado'] = filtrado.cliente.replaceAll('<br>', ' ');
                    csv['Operario'] = filtrado.operario.replaceAll('<br>', ' ');
                    csv['Tramitador'] = filtrado.tramitador;
                    csv['Especialidad'] = filtrado.especialidad;
                    csv['Resumen de parte de trabajo'] = filtrado.descripcion_visitadores;
                    listado.push(csv);
                });
                
                let fechahoy = '_F' + new Date().toLocaleDateString().replaceAll('/', '-') + '_T' + new Date().toLocaleTimeString().replaceAll(':', '-');
               jsonToCsvExport({ data: listado, filename: 'Descarga_avisos_' + fechahoy, delimiter: ";" });      
           }catch (e) {                
               this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
           }
            //let text = "hola mondo";
           
        },
        async obtenerEspecialidades() {
            //GET /pwgsapi/index.php/especialidades
            const api = new PwgsApi();
            let aux = await api.get('especialidades');
            this.especialidades = aux.datos;
        },
        toggleOrden(campo) {
                this.ordenarPor = campo;
                if(campo == 'fecha_crear_visita'){
                    this.fechaInicioOrdenAscendente = !this.fechaInicioOrdenAscendente;
                    this.fechaInicioIcon = this.fechaInicioOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                    this.codigoOrdenAscendente = false;
                    this.codigoIcon = 'fas fa-sort-up';
                    this.operarioAscendente = false;
                    this.operarioIcon = 'fas fa-sort-up';
                }else if(campo == 'codigo'){
                    this.codigoOrdenAscendente = !this.codigoOrdenAscendente;
                    this.codigoIcon = this.codigoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                    this.fechaInicioOrdenAscendente = false;
                    this.fechaInicioIcon = 'fas fa-sort-up';
                    this.operarioOrdenAscendente = false;
                    this.operarioIcon = 'fas fa-sort-up';
                }else{
                    this.operarioOrdenAscendente = !this.operarioOrdenAscendente;
                    this.operarioIcon = this.operarioOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                    this.codigoOrdenAscendente = false;
                    this.codigoIcon = 'fas fa-sort-up';
                    this.fechaInicioOrdenAscendente = false;
                    this.fechaInicioIcon = 'fas fa-sort-up';
                }
            },
            parseDate(dateString) {
                const [day, month, year] = dateString.split('/');
                return new Date(`${year}-${month}-${day}`);
            },
        async confirmar(exp){
            const api = new PwgsApi();
            var fechainicioconvertida = this.convertir(exp.fecha_inicio);
            var fechafinconvertida = this.convertir(exp.fecha_fin);
            await api.put('/planning-pwgs/'+exp.id_servicio+'/confirmar-visita/'+exp.id_aviso,{fecha_cita_inicio:fechainicioconvertida.split(', ')[0], fecha_cita_fin:fechafinconvertida.split(', ')[0], hora_cita_inicio:fechainicioconvertida.split(', ')[1], hora_cita_fin:fechafinconvertida.split(', ')[1]});
            this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Visita confirmada correctamente', life: 2000 });
            this.obtenerExpedientes();
            this.OperarioSeleccionado=[];
            this.especialidadSeleccionada=[];
        },
        convertir(fecha){
            return fecha.toLocaleString();
        },
        convertirAFecha(fechaStr) {
            // Asume que el formato es "dd/mm/yyyy hh:mm"
            const [fecha, hora] = fechaStr.split(' ');
            const [dia, mes, año] = fecha.split('/');
            const [horas, minutos] = hora.split(':');
            
            return new Date(año, mes - 1, dia, horas, minutos);
        },
           async obtenerExpedientes() {
            
                this.loading = true;
               const api = new PwgsApi;       
               var aux = await api.post('avisos/visitas/'+this.tipo_servicio);    
                this.expedientes = aux.datos.map(expediente => {
                    return {
                        ...expediente,
                        fecha_inicio: this.convertirAFecha(expediente.fecha_inicio)
                    };
                });
                this.expedientes = this.expedientes.map(expediente => {
                    return {
                        ...expediente,
                        fecha_fin: this.convertirAFecha(expediente.fecha_fin)
                    };
                });
                this.expedientes = this.expedientes.map(expediente => {
                    var tramitadorEncontrado = this.tramitadores.filter(tramitador => tramitador.id == expediente.id_tramitador); 
                    console.log('tramitadores', this.tramitadores, expediente, tramitadorEncontrado);    
                    if(tramitadorEncontrado.length > 0){
                        return {
                            ...expediente,
                            tramitador: tramitadorEncontrado[0].nombre
                        };
                    }else{
                        return{
                            ...expediente,
                            tramitador: ''
                        }
                    }
                });
                console.log('expedientess', this.expedientes);
               this.total = aux.n_total_registros;
               localStorage.setItem("totalavisos", this.total);
               console.log('expeds', this.expedientes);
               this.loading = false;
           },
           async obtenerOperarios() {
            const api = new PwgsApi();
            var aux = await api.get('operarios/simple');
            this.operarios = aux.datos;
           },
           seleccionartodo(){
            for(let expediente of this.expedientes){
                expediente.seleccionado = this.checkGlobal;
            }
           },
           async obtenerTramitadores(){
            const api = new PwgsApi();
            const resp = await api.get('usuarios/Gestión?sortField=nombre&sortOrder=1');
            this.tramitadores = resp.datos;
            this.obtenerExpedientes();
           },
       },
       created() {
        this.obtenerEspecialidades();        
        this.obtenerOperarios();
        this.obtenerTramitadores();
       },        
       watch:{
        checkGlobal(){            
            this.seleccionartodo();            
        }
       }
   }
   </script>
   <style>
   .p-dialog {
       width: 90%;
   }
   .p-datatable-header{
   
       visibility:hidden;
   }
   
   </style>