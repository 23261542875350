<template>
    <div class="callout" style="margin: 0;">
        <Button icon="fas fa-file-excel" class="btn btn-secondary" label="Exportar" style="height: 42px;" @click="exportarcsv()" />
    </div>
    <div style="margin-top: 10px;">
        <tabla-datos :botones="true" :columnas="columnas" :datos="expedientes" :filtros="filtros" :avisosConFiltro="true" :estadospend="estadospend"
            :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerExpedientes" sortField="codigo" :tramitadores="tramitadores">
        </tabla-datos>
    </div>

</template>

<script>
import jsonToCsvExport from "json-to-csv-export";
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
import { FilterMatchMode } from 'primevue/api';

export default {
    components: {

        "tabla-datos": TablaDatos,
        
    },
    props: {
        tipo_servicio: String
    }
    ,
    data() {
        return {
            parametrosactuales:'',
            estadospend:'',
            tramitadores:'',
            total: '',
            expedientes: [],
            parametros: '',
            filtrosBuscador: ['codigo'],
            filtros: {
                'codigo': { value: "", matchMode: FilterMatchMode.CONTAINS },
            },
            columnas: [
                {
                    header: 'Expediente',
                    field: 'codigo',
                    sortable: false,
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id, codigo) => {
                                localStorage.setItem('nombreservicio' + id, codigo);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id,
                                        nombreservicio: codigo,
                                    }
                                });
                            }
                        },
                    ]
                },
                {
                    header: 'Fecha',
                    field: 'fecha',
                    sortable: true,
                },
                {
                    field: 'tramitador',
                    sortable: false,
                    tramifilt:'tramifilt'
                },
                {
                    field: 'pendiente',
                    sortable: false,
                    espependfilt:'espependfilt'
                },
                {
                    header: 'Tipo',
                    field: 'tipo_dano',
                    sortable: false,
                },
                {
                    header: 'Días Apertura',
                    field: 'dias_apertura',
                    sortable: true,
                },
                {
                    header: 'Fecha espera',
                    field: 'fecha_espera',
                    sortable: true,
                },
                {
                    header: '',
                    field: 'dias_sin_actualizar',
                    sortable: true,
                    title: 'Acciones que actualizan: 1. Nueva cita. 2. Nueva nota 3. Nuevo mensaje 4. Envío de presupuesto'
                },
                {
                    header: 'Perito',
                    field: 'perito',
                    sortable: false,
                },
                {
                    header: 'Estado',
                    field: 'estado',
                    sortable: false,
                },

            ]
        }
    },
    methods: {
        async exportarcsv() {
           const api = new PwgsApi();
           try{
               this.$toast.add({ severity: 'success', summary: 'Descarga iniciada', detail: 'Descargando...', life: 5000 });               
               const aux = await api.post('listados-servicios/' + this.tipo_servicio, this.parametrosactuales);
               var expedientes = aux.datos;
                let filtrados = expedientes;
                var listado = [];
                filtrados.forEach(filtrado => {
                    let csv = {};
                    csv['Nº Expediente'] = filtrado.codigo;
                    csv['Fecha'] = filtrado.fecha;
                    csv['Tramitador'] = filtrado.tramitador;
                    const encodedText = filtrado.pendiente;

                    // Convertimos el texto a un buffer de bytes interpretado como ISO-8859-1
                    const bytes = new Uint8Array([...encodedText].map(char => char.charCodeAt(0)));

                    // Usamos TextDecoder para decodificar correctamente como UTF-8
                    const decoder = new TextDecoder('utf-8');
                    const decodedText = decoder.decode(bytes);

                    csv['Pendiente de'] = decodedText;
                    csv['Tipo'] = filtrado.tipo_dano;
                    csv['Días apertura'] = filtrado.dias_apertura;
                    csv['Días sin actualizar'] = filtrado.dias_sin_actualizar;
                    csv['Perito'] = filtrado.perito;
                    csv['Estado'] = filtrado.estado;
                    listado.push(csv);
                });
                
                let fechahoy = '_F' + new Date().toLocaleDateString().replaceAll('/', '-') + '_T' + new Date().toLocaleTimeString().replaceAll(':', '-');
                jsonToCsvExport({ data: listado, filename: 'Descarga_avisos_' + fechahoy,delimiter:";" });      
           }catch (e) {                
               this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
           }
            //let text = "hola mondo";
           
        },
        //https://sonia.pwgs.app/modulos/pwgsapi/index.php/listados-servicios/activos
        async obtenerExpedientes(parametros) {
            const params = parametros;
            params.tipo_aviso_alerta = localStorage.getItem("tipo_alerta");
            console.log('obtenerExpedientes(avisos)', params);
            this.parametrosactuales = params;
            this.columnas[0].header = this.$t("general.expediente");
            this.columnas[1].header = this.$t("general.fecha");
            this.columnas[4].header = this.$t("general.tipo");
            this.columnas[5].header = this.$t("general.diasapertura");
            this.columnas[6].header = this.$t("general.diassinactualizar");
            this.columnas[7].header = this.$t("general.perito");
            this.columnas[8].header = this.$t("general.estado");
            if(this.tipo_servicio=='esperas_caducadas_Fechaesperacaducada'){
                var nuevo = {
                    header: 'Fecha Espera',
                    field: 'fecha_espera',
                    sortable: false,
                };
                this.columnas.splice('6','0',nuevo);
            }
            const api = new PwgsApi;
            const aux = await api.post('listados-servicios/' + this.tipo_servicio, params);
            this.expedientes = aux.datos;
            this.total = aux.n_total_registros;
            localStorage.setItem("totalavisos", this.total);
            this.$emit('evento_total_asignaciones', this.total); //Es llamado por ./Escritorio.vue
        },
        async cargarTramitadoresyestados() {
            const api = new PwgsApi();
            const resp = await api.get('usuarios/Gestión?sortField=nombre&sortOrder=1');
            this.tramitadores = resp.datos;
            const resp2 = await api.get('tipos-estado-pendiente');
            this.estadospend = [
            {
                label:'Estados pendientes',
                items:resp2.datos.estados_pendientes
            },
            {
                label:'Especialidades',
                items:resp2.datos.especialidades
            }]
        },  
    },
    activated() {        
        this.obtenerExpedientes();
    },
    mounted() {
        this.cargarTramitadoresyestados();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}

.p-datatable-header {

    visibility: hidden;
}
</style>