<template>
    <div class="callout">
        <div style="display: flex; gap: 25px; flex-wrap: wrap;">
            <input style="height: 42px;width:250px" type="text" class="form-control" placeholder="Expediente"
                v-model="filtroExpediente">
            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="companiaseleccionada"
                :options="companias" filter optionLabel="nombre" style="width:250px" placeholder="Compañías">
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div v-if="slotProps.option.nombre.length <= 50">{{ slotProps.option.nombre }}<span
                                v-if="slotProps.option.prefijo">&nbsp;({{ slotProps.option.prefijo }})</span></div>
                        <div v-else>{{ slotProps.option.nombre.slice(0, 47) }}...<span
                                v-if="slotProps.option.prefijo">&nbsp;({{ slotProps.option.prefijo }})</span></div>

                    </div>
                </template>
            </MultiSelect>

            <MultiSelect optionValue="id" scrollHeight="400px" showClear v-model="usuarioseleccionado"
                :options="usuarios" filter optionLabel="nombre" style="width:250px" placeholder="Usuarios">
            </MultiSelect>
            <button class="btn btn-primary" @click="obtenerExpedientes(mievento)">Buscar</button>
        </div>
    </div>
    <tabla-datos :asignaciones="true" :botones="true" :columnas="columnas" :datos="expedientes" :filtros="false"
        :servicios="true" v-if="mostrar" :page="pageselec" :num="numdatos" :total="total"
        @quieroDatos="obtenerExpedientes($event); mievento = $event;" @page="onPage($event)">
    </tabla-datos>
</template>   
<script>
import MultiSelect from 'primevue/multiselect';
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
export default {
    components: {
    MultiSelect,
        "tabla-datos": TablaDatos,
    },
    data() {
        return {
        mievento:[],
        usuarioseleccionado:'',
        companiaseleccionada:'',
            mostrar:false,
            filtroExpediente: '',
            companias:[],
            usuarios:[],
            pageselec:1,
            numDatos:15,
            total: '',
            expedientes: [],
            parametros: '',
            columnas: [
                {
                    header:'Tipo',
                    field: 'class',

                },
                {
                    header: 'Expediente',
                    field: 'codigo_servicio',
                    sortable: false,
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            nombreId: 'id_servicio',
                            nombreIddos: 'codigo_servicio',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id, codigo) => {
                                console.log('Editar expediente ' + id);
                                localStorage.setItem('nombreservicio' + id, codigo);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id,
                                        nombreservicio: codigo,
                                    }
                                });
                            }
                        },
                    ]
                },
                {
                    header: 'Compañia',
                    field: 'nombre_compania',
                    sortable: false,
                },
                {
                    header: 'Fecha/Hora',
                    field: 'fecha_hora',
                    sortable: true,
                },
                {
                    header: 'Gremio',
                    field: 'gremio_servicio',
                    sortable: false,
                },
                {
                    header: 'Direción',
                    field: 'direccion_cliente',
                    sortable: false,
                },

                {
                    header: 'Contacto',
                    field: 'telefonos_cliente',
                    sortable: false,
                },
                {
                    header: 'Tramitador',
                    field: 'nombre_tramitador',
                    sortable: false,
                },
            ]
        }
    },
    methods: {        
        async cargarCompanias() {
            const api = new PwgsApi();
            const datos = await api.get('companias/simple?sortField=nombre&sortOrder=1');
            this.companias = datos.datos;
        },
        async cargarUsuarios() {
            const api = new PwgsApi();
            const datos = await api.get('usuarios/gestion,simple');
            this.usuarios = datos.datos;
        },
        onPage(evnt){
            localStorage.setItem("pageselecasig", evnt.page);
            localStorage.setItem("numdatsasig", evnt.rows);
            console.log("evvvv", evnt);
        },
        async obtenerExpedientes(event) { 
            console.log('asignacioneslan');
            if (this.companiaseleccionada) {
            event.ids_companias = this.companiaseleccionada;
            }
            if (this.usuarioseleccionado) {
            event.ids_usuarios = this.usuarioseleccionado;
            }
            if(this.filtroExpediente !=''){
                event.buscador = this.filtroExpediente;
            }else{
                delete event.buscador;
            }
            const api = new PwgsApi;
            const aux = await api.post('avisos/asignaciones',event);
            this.expedientes = aux.datos;
            this.total = aux.n_total_registros;
            this.$emit('evento_total_asignaciones', this.total); //Es llamado por ./Escritorio.vue
        },
    },

        mounted() {
        this.cargarCompanias();
        this.cargarUsuarios();
        this.pageselec = parseInt(localStorage.getItem('pageselecasig'));
        this.numdatos = parseInt(localStorage.getItem('numdatsasig'));
        console.log('pageselec',this.pageselec);
        this.mostrar = true;
        // this.obtenerExpedientes();
    },
    watch:{
    usuarioseleccionado(){
        this.pageselec = 1;
        this.mievento["first"]=0;
        if (!this.usuarioseleccionado) {
            delete this.mievento["ids_usuarios"];
        }
    },
    companiaseleccionada(){
        this.pageselec = 1;
        this.mievento["first"]=0;
        if (!this.companiaseleccionada) {
            delete this.mievento["ids_companias"];
        }
    },
    filtroExpediente(){
        this.pageselec = 1;
        this.mievento["first"]=0;
    }
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
.p-datatable-header{

    visibility:hidden;
}

</style>