<template>
    <li class="nav-item dropdown " style="position: relative;">
        <a :class="aauxserv" data-toggle="dropdown" @click=" cambiarclases" :id="'av' + vienede" href="#">
            <i class="far fa-bell"></i>&nbsp;<span id="avisotitulo" v-if="vienede == 'escritorio'">{{ tipoaviso }}</span>&nbsp;
            <span id="numeroavisos" class="badge badge-danger" v-if="vienede == 'escritorio'">{{ numero_avisos }}</span>
            <span class="badge badge-danger" v-if="!vienede || vienede != 'escritorio'">{{ totalavisos }}</span>

        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
            style="height: auto; max-height: 90vh; overflow-x: hidden; min-width: 400px;">
            <div class="dropdown-divider"></div>
            <ListaAvisos :vienede="vienede" :avisos="avisos"
                v-on:evento_expediente_avisos_tipo="cargarExpedienteAvisos" />
        </div>
    </li>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import ListaAvisos from './AvisosLista.vue';


export default ({
    components: {
        ListaAvisos
    },
    props: {
        tipo_servicio: String,
        seccionavisos: Boolean,
        vienede: String,
    }
    , data() {
        return {
            avisos: [],
            numero_avisos: '',
            tipoaviso: '',
            aauxserv: 'nav-link',
            totalavisos: '',
        }
    },
    methods: {
        async cargardatos() {
            /* get  modulos/pwgsapi/index.php/avisos */
            const api = new PwgsApi;
            const datos = await api.post('avisos');
            this.avisos = datos.datos;
            //this.numero_avisos = datos.n_total_avisos;
            this.totalavisos = datos.n_total_avisos;
            console.log('avss', this.avisos);
            
            if (this.$props.seccionavisos == false) {
                this.aauxserv = 'nav-link';

            }
            if (this.$props.seccionavisos == true) {
                this.aauxserv = 'nav-link active';
            }
        },
        cambiarclases() {
            console.log("entra44444");
            document.getElementById("avescritorio").className = document.getElementById("av").className;
            this.aauxserv = 'nav-link active';


        },
        cambiartotalpadre(dato) {
            console.log("holapadre", dato);
            let aux = "";

            if (dato) {
            this.numero_avisos = dato;
                aux = dato;
            }
            return aux;
        },
        removeractivos() {
            try {
                this.$parent.removeractivo();
            }
            catch (error) {
                console.log("error");
            }
            //this.aauxserv = 'nav-link';
            //document.getElementById('av').className = 'nav-link active';


        },
        activo() {
            //  setTimeout(() => {
            console.log("asdfgh");
            //this.aauxserv = 'nav-link';
            console.log("vienede1", this.$props.vienede);

            //    }, 2000);
        },
        cargarExpedienteAvisos: function (tipo, titulo, alerta) {
            this.tipoaviso = titulo;
            //this.numero_avisos = 0;
            setTimeout(() => {
             //   this.numero_avisos = localStorage.getItem('totalavisos');
            }, 5000);
            localStorage.setItem("tipobuscador", tipo);
            localStorage.setItem("tituloaviso", titulo);
            localStorage.setItem("tipo_alerta", alerta);
            this.$router.push({ name: 'Escritorio', query: { tipo_buscador: tipo , titulo: titulo } })
           // this.removeractivos();
            //this.aauxserv = 'nav-link active';
            //document.getElementById('av').className = 'nav-link active';
        }


    },
    mounted() {
        this.cargardatos();
        console.log("vienede2", this.$props.vienede);

    },

    watch: {
        tipo_servicio() {
            console.log("123saddd");
            //this.aauxserv = 'nav-link active';
            //document.getElementById('av').className = 'nav-link active';


        },
        $route() {
            this.tipoaviso = localStorage.getItem("tituloaviso");

        },
        seccionavisos() {
            console.log("seccionavisos", this.$props.seccionavisos);
            if (this.$props.seccionavisos == false) {
                this.aauxserv = 'nav-link';

            }
            if (this.$props.seccionavisos == true) {
                this.aauxserv = 'nav-link active';
            }
        }
    }
})
</script>
<style scoped></style>
